<template>
  <div class="w-100 my-5">
    <div
      class="pb-4 text-center text-white font-weight-bold f-size-22"
      v-if="activeLanguage == 'th'"
    >
      <div>{{ $t("msgWelcome") }}</div>
      <div>{{ $t("msgKerryClub") }}</div>
    </div>
    <div class="pb-4 text-center text-white font-weight-bold f-size-22" v-else>
      <div>Welcome to KERRY CLUB</div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="card p-3 mx-3 login-card mb-5">
        <div class="f-size-22 text-center mb-4 font-weight-bold text-gray-1">
          {{ $t("signUp") }}
        </div>
        <div class="py-4">
          <InputText
            :textFloat="$t('mobileNo')"
            placeholder="xxx-xxx-xxxx"
            type="tel"
            name="mobileNo"
            isRequired
            @onKeypress="isNumber($event)"
            v-model="form.mobileNo"
            :isValidate="v.form.mobileNo.$error"
            :v="v.form.mobileNo"
          />
          <InputText
            :textFloat="$t('password')"
            :placeholder="$t('password')"
            type="password"
            name="password"
            isRequired
            v-model="form.password"
            :isValidate="v.form.password.$error"
            :v="v.form.password"
          />
        </div>
        <div class="">
          <b-button
            class="w-100 btn-main py-2"
            @click="handleNextStep"
            :disabled="isDisble"
            >{{ $t("signUp2")
            }}<b-spinner
              class="align-middle w-1rem ml-2"
              v-if="isRegister"
            ></b-spinner
          ></b-button>
        </div>
        <div class="text-center py-3">
          <span>{{ $t("haveAccount") }}</span>
          <span
            class="font-weight-bold pointer"
            @click="
              $router.push(
                '/login/' + activeLanguage + '?sessionId=' + sessionId
              )
            "
          >
            {{ $t("login2") }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
export default {
  components: {
    InputText
  },
  props: {
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    isRegister: {
      required: true,
      type: Boolean
    },
    isDisble: {
      required: true,
      type: Boolean
    },
    sessionId: {
      required: true,
      type: String
    },
    activeLanguage: {
      required: true,
      type: String
    }
  },
  data() {
    return {};
  },
  validations() {
    return {};
  },

  methods: {
    handleNextStep() {
      this.$emit("handleNextStep", 2);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-img {
  width: 100px;
  height: auto;
  text-align: center;
}
.login-header {
  height: 60px;
  border-bottom: 2px solid white;
}
.login-container {
  height: calc(100vh - 60.5px);
}
.login-card {
  width: 100%;
}
::v-deep .card {
  border-color: white;
  border-radius: 14px;
}
.w-1rem {
  width: 1rem;
  height: 1rem;
}
@media only screen and (min-width: 767.98px) {
  .login-card {
    max-width: 450px;
  }
}
</style>